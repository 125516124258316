@font-face{
    font-family: "AppleSDGothicNeoM";
    font-weight: normal;
    src: url("./Fonts/AppleSDGothicNeoM.ttf") format("truetype");
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body{
    background-color: #f5f5f7;
    margin: 0px;
    overflow-y: overlay;
    font-family: "AppleSDGothicNeoM";
}

::-webkit-scrollbar{
    width: 14px;
    height: 14px;
}

::-webkit-scrollbar-thumb{
    outline: none;
    border-radius: 10px;
    border: 4px solid transparent;
    box-shadow: inset 6px 6px 0 rgba(34,34,34,0.15);
}

::-webkit-scrollbar-thumb:hover{
    border: 4px solid transparent;
    box-shadow: inset 6px 6px 0 rgba(34,34,34,0.15);
}

::-webkit-scrollbar-track{
    box-shadow: none;
    background-color: transparent;
}

